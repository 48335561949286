<template>
	<div class="common-layout" v-loading.fullscreen="page_data_loading">
		<el-row>
			<el-col :span="22">
				<el-form :inline="true" :model="searchForm" label-width="100px">
					<el-form-item label="Order Date:">
						<el-col :span="11">
							<el-date-picker popper-class="order_date_picker_popper" clearable
								v-model="searchForm.order_data_start" type="date" placeholder="Start Date"
								format="MM/DD/YYYY" value-format="YYYY-MM-DD" :shortcuts="shortcuts"
								@visible-change="onVisibleChange">
							</el-date-picker>
						</el-col>
						<el-col :span="2" class="text-center">
							<span class="text-gray-500">&nbsp;&nbsp;To</span>
						</el-col>
						<el-col :span="11">
							<el-date-picker popper-class="order_date_picker_popper" clearable
								v-model="searchForm.order_data_end" type="date" placeholder="End Date" format="MM/DD/YYYY"
								value-format="YYYY-MM-DD" @visible-change="onVisibleChange">
							</el-date-picker>
						</el-col>
					</el-form-item>
					<el-form-item label="Billing CID:">
						<el-select v-model="searchForm.billto_cid_ary" multiple collapse-tags collapse-tags-tooltip
							:disabled="!can_change_billing_to_cid" placeholder="Select">
							<el-option v-for="(item, index) in billing_cids" :key="index" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="Order Type:">
						<el-select v-model="searchForm.order_type_ary" collapse-tags collapse-tags-tooltip
							placeholder="Select">
							<el-option v-for="(item, index) in order_types" :key="index" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="Model:">
						<el-input v-model="searchForm.model_name" placeholder="Please input Model">
							<template #prepend>
								<el-icon @click="open_multiline_box()">
									<MoreFilled />
								</el-icon>
							</template>
						</el-input>
					</el-form-item>
				</el-form>
			</el-col>
			<el-col :span="2"><el-button type="primary" @click="loadPageData()">Search</el-button></el-col>
		</el-row>

		<el-row :gutter="20" class="mt-2">
			<el-col :span="13">
				<TableList :columns="ColumnsOrder.columns" :data="dataByOrder" :totalData="dataByOrder_total"
					:title="ColumnsOrder.headerTitle" :tableIndex="3"
					:tableTips="`Margin% has deducted rebates but not ads.`" />
			</el-col>
			<el-col :span="11">
				<TableList :columns="ColumnsSummary.columns" :data="dataByBillto" :totalData="dataByBillto_total"
					:title="ColumnsSummary.headerTitle" :tableIndex="2" />
			</el-col>
		</el-row>
		<el-row :gutter="20" class="mt-3">
			<el-col :span="24">
				<TableList :columns="ColumnsModel.columns" :data="dataByModel" :totalData="dataByModel_total"
					:title="ColumnsModel.headerTitle" :tableIndex="1" />
			</el-col>
		</el-row>

		<el-row :gutter="20" :class="main_mark_data ? 'sales-map-box' : ''">
			<el-col :span="24" class="mt-3 mb-3">
				<span>Warehouse Type:</span>
				<el-select v-model="searchForm.warehouse_type" collapse-tags collapse-tags-tooltip placeholder="Select"
					@change="handleSalesMapWarehouseType" class="ms-3">
					<el-option v-for="(item, index) in warehouse_type" :key="index" :label="item.label" :value="item.value">
					</el-option>
				</el-select>

				<span class="ms-4 text-danger">Total:</span>
				<span class="ms-2 text-danger">$ {{ salesMapTotal }}</span>
			</el-col>
			<el-col :span="18">
				<div id="sales_map_echart" class="" style="margin: 0, auto; width: 100%; height: 100%"></div>
			</el-col>
			<el-col :span="6">
				<div id="sales_map_bar" class="sales_map_bar_box">
					<ul>
						<li ref="barItemRefs" v-for="(item, index) in map_bar" :key="index" class="sales_map_bar_item"
							@mouseenter="salesMapBarHover(index, item.name)" @mouseleave="salesMapBarOut">
							<div class="d-flex justify-content-between">
								<span>{{ item.item_name }}</span>
								<span class="">(Orders: {{ item.item_order_count }} ,
									{{ item.item_amount }})</span>
							</div>

							<div class="sales_map_bar_item_all">
								<div class="sales_map_bar_item_tv" :style="item.item_style"></div>
							</div>
						</li>
					</ul>
				</div>
			</el-col>
		</el-row>

		<!-- Multiline -->
		<el-dialog v-model="dialogMultilineVisible" :title="dialogMultilineTitle" :close-on-press-escape="false"
			:close-on-click-modal="false" width="300px">
			<span class="text-danger">{{ dialogMultilineNote }}</span>

			<el-input type="textarea" :rows="8" v-model="multiline_box" @input="checkMutilineInput">
			</el-input>

			<template #footer>
				<div>
					<el-button type="primary" @click="saveMultiline">OK</el-button>
					<el-button type="primary" @click="dialogMultilineVisible = false;">Cancel</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
import {
	NumericTothreeDigit,
	isEmpty,
	isNotEmpty,
	cloneDeepObject,
} from "@/utils/util.js";
import { ACCESS_TOKEN, LOGIN_IN_USERNAME } from "@/store/mutation-types";

import { reactive, ref, toRefs, watch, computed, onActivated} from "vue";
import { useRouter, useRoute } from "vue-router";
import {
	getInitPageData,
	getPageData,
	getMapSourceData,
} from "@/api/dashboard";
import { logout } from "@/api/login";
import { ElMessageBox, ElMessage } from "element-plus";
import TableList from "../components/TableList.vue";
import { UserOutlined } from "@ant-design/icons-vue";
import {
	ColumnsModel,
	ColumnsOrder,
	ColumnsSummary,
} from "@/config/tableColumns.js";
import * as echarts from "echarts";
import { MapChart } from "echarts/charts";

echarts.use([MapChart]);

import moment from "moment";

export default {
	name: "index",
	components: {
		UserOutlined,
		TableList,
	},
	mounted() {
		var _self = this;
		this.initPage();

		window.onresize = function () {
			if (typeof _self.sales_map_echart != "undefined") {
				setTimeout(function () {
					_self.sales_map_echart.resize();
				}, 200);
			}
		};
	},
	setup() {
		var sales_map_echart;

		const barItemRefs = ref(null);

		const my_setup = reactive({
			collapsed: false,
			page_data_loading: false,
			initedDatePicker: false,
			show_order_type: true,
			can_change_billing_to_cid: true,

			ColumnsModel: ColumnsModel,
			ColumnsOrder: ColumnsOrder,
			ColumnsSummary: ColumnsSummary,
			searchForm: {
				order_data_start: "",
				order_data_end: "",
				billto_cid_ary: [],
				order_type_ary: "",
				model_name: "",
				warehouse_type: "",
			},
			billing_cids: [],
			order_types: [
				{
					label: "ALL",
					value: "",
				},
				{
					label: "Domestic Warehouse/D+F",
					value: "domestic_warehouse",
				},
				{
					label: "Dropship",
					value: "dropship;",
				},
				{
					label: "Fulfillment",
					value: "fulfillment;",
				},
				{
					label: "Oversea Direct",
					value: "direct;",
				},
				{
					label: "3PL TX",
					value: "3pltx;",
				},
				{
					label: "3P",
					value: "3p;",
				}
			],
			warehouse_type: [
				{
					label: "All",
					value: "",
				},
				{
					label: "End User",
					value: "end_user",
				},
				{
					label: "Fulfillment Center",
					value: "fulfillment_center",
				},
			],
			dataByModel: [],
			dataByModel_total: [],
			dataByOrder: [],
			dataByOrder_total: [],
			dataByBillto: [],
			dataByBillto_total: [],

			//sales map
			mapName: "usa",
			mapName_thumb: "usa",
			main_map_option: {
				tooltip: {
					trigger: "item",
					showDelay: 0,
					transitionDuration: 0.2,
					formatter: function (data) {
						return (
							data.name +
							"<br/>" +
							"Orders: " +
							(isNotEmpty(data.data)
								? NumericTothreeDigit(data.data.order_count, 0) || 0
								: 0) +
							" , $" +
							NumericTothreeDigit(data.value || 0, 2)
						);
					},
				},
				visualMap: {
					right: "right",
					bottom: "bottom",
					min: 0,
					max: 38000000,
					type: "piecewise",
					splitNumber: 10,
					inRange: {
						color: [
							// '#45edb2', '#46ed84', '#58ed5f', '#68e245', '#72d645', '#ec4544', '#f32D2D', '#f13535', '#f91515', '#ff0000'
							"#bff7bf",
							"#8aec8a",
							"#46ed84",
							"#55e355",
							"#00c300",
							"#ee9595",
							"#e35555",
							"#ef4343",
							"#ff2020",
							"#d31616",
						],
					},
					text: ["High", "Low"],
					calculable: true,
					orient: "vertical",
				},
				toolbox: {
					feature: {
						myTool: {
							show: true, //是否显示
							name: "Back", //鼠标悬空的提示文字
							title: "Back", //鼠标悬空的提示文字
							icon: "path://M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z", //这个是阿里icon svg 后 b的全部字符串
							onclick: (o) => {
								//绑定点击事件sales_map_echart
								backToMainMapData();
							},
						},
					},
					left: "left",
					top: "top",
				},
				top: 0,
				left: 0,
				series: [
					{
						name: "USA PopEstimates",
						type: "map",
						roam: true,
						map: "USA",
						zoom: 1.1,
						scaleLimit: {
							min: 1.0,
							max: 1.1,
						},
						itemStyle: {
							areaColor: "#73eadc",
							borderColor: "#f2f2f2",
						},
						label: {
							show: false,
							color: "#000",
						},
						emphasis: {
							label: {
								show: true,
							},
						},
						data: [],
					},
				],
			},
			main_mark_data: [],
			main_mark_data_storage: [],
			map_bar: [],

			/* dialogMultiline */
			dialogMultilineVisible: false,
			dialogMultilineTitle: 'Please input Models',
			dialogMultilineNote: 'One Model per line',
			multiline_type: 1,
			multiline_box: '',
		});

		const shortcuts = [
			{
				text: 'Today',
				value: () => {
					const end = moment().format('YYYY-MM-DD');
					const start = moment().format('YYYY-MM-DD');

					my_setup.searchForm.order_data_end = end;
					return start
				}
			},
			{
				text: 'Yesterday',
				value: () => {
					const end = moment().subtract(1, 'days').format('YYYY-MM-DD');
					const start = moment().subtract(1, 'days').format('YYYY-MM-DD');

					my_setup.searchForm.order_data_end = end;
					return start
				}
			},
			{
				text: 'This week',
				value: () => {
					const end = moment().week(moment().week()).endOf('week').format('YYYY-MM-DD');
					const start = moment().week(moment().week()).startOf('week').format('YYYY-MM-DD');

					my_setup.searchForm.order_data_end = end;
					return start
				}
			},
			{
				text: 'Last month',
				value: () => {
					const end = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
					const start = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');

					my_setup.searchForm.order_data_end = end;
					return start
				}
			},
			{
				text: 'Last 3 months',
				value: () => {
					let moment_now = moment();
					const end = moment_now.subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
					const start = moment_now.subtract(2, 'months').startOf('month').format('YYYY-MM-DD');

					my_setup.searchForm.order_data_end = end;
					return start
				}
			},
			{
				text: 'Last 6 months',
				value: () => {
					let moment_now = moment();
					const end = moment_now.subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
					const start = moment_now.subtract(5, 'months').startOf('month').format('YYYY-MM-DD');

					my_setup.searchForm.order_data_end = end;
					return start
				}
			}, {
				text: 'Last 12 months',
				value: () => {
					let moment_now = moment();
					const end = moment_now.subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
					const start = moment_now.subtract(11, 'months').startOf('month').format('YYYY-MM-DD');

					my_setup.searchForm.order_data_end = end;
					return start
				}
			}, {
				text: 'Last quarter',
				value: () => {
					let moment_now = moment();
					const end = moment().quarter(moment_now.quarter() - 1).endOf('quarter').format('YYYY-MM-DD');
					const start = moment().quarter(moment_now.quarter() - 1).startOf('quarter').format('YYYY-MM-DD');

					my_setup.searchForm.order_data_end = end;
					return start
				}
			}, {
				text: 'Last year',
				value: () => {
					let moment_now = moment();
					const end = moment().year(moment_now.year() - 1).endOf('year').format('YYYY-MM-DD');
					const start = moment().year(moment_now.year() - 1).startOf('year').format('YYYY-MM-DD');

					my_setup.searchForm.order_data_end = end;
					return start
				}
			}, {
				text: 'This month',
				value: () => {
					let moment_now = moment();
					const end = moment_now.format('YYYY-MM-DD');
					const start = moment_now.startOf('month').format('YYYY-MM-DD');

					my_setup.searchForm.order_data_end = end;
					return start
				}
			}, {
				text: 'This quarter',
				value: () => {
					let moment_now = moment();
					const end = moment_now.format('YYYY-MM-DD');
					const start = moment_now.startOf('quarter').format('YYYY-MM-DD');

					my_setup.searchForm.order_data_end = end;
					return start
				}
			}, {
				text: 'This year',
				value: () => {
					let moment_now = moment();
					const end = moment_now.format('YYYY-MM-DD');
					const start = moment_now.startOf('year').format('YYYY-MM-DD');

					my_setup.searchForm.order_data_end = end;
					return start
				}
			}
		]

		const router = useRouter();
		const route = useRoute();

		onActivated(()=>{
			const reloadPage = route.meta.reloadPage
			if(reloadPage === true){
				loadPageData()
			}
		})
		watch(
			() => my_setup.mapName,
			() => {
				handleSalesMapChildState();
			}
		);
		watch(
			() => route.name,
			(n, o) => {
				if (n !== o && n == "dashboard") {
					//loadPageData();
				}
			},
			{ immediate: false }
		);
		watch(
			() => route.query.id,
			(n, o) => {
				let route_name = route.name;
				if (n !== o && route_name == "dashboard") {
					if (isEmpty(n)) {
						my_setup.searchForm.billto_cid_ary = [];
					}
					loadPageData();
				}
			},
			{ immediate: false }
		);

		my_setup.salesMapTotal = computed(() => {
			let main_mark_data = my_setup.main_mark_data || [];

			let total_p = 0;
			main_mark_data.forEach((item) => {
				if (!_.isNaN(_.toNumber(item.value))) {
					total_p += _.toNumber(item.value);
				}
			});

			return NumericTothreeDigit(total_p, 2);
		});
		function defaultOrderdate() {
			let weekday = moment().weekday()
			let now_date = moment().format("YYYY-MM-DD");

			if(weekday === 0 || weekday === 1){
				now_date = moment().subtract(1, 'w').startOf('week').add(5,'day').format("YYYY-MM-DD");
			}else{
				now_date = moment().subtract(1, 'days').format("YYYY-MM-DD");
			}

			my_setup.searchForm.order_data_start = now_date;
			my_setup.searchForm.order_data_end = now_date;
		}
		const username = sessionStorage.getItem(LOGIN_IN_USERNAME);
		function handelLogout() {
			logout().then((res) => {
				if (res.code == 200) {
					sessionStorage.removeItem(ACCESS_TOKEN);
					sessionStorage.removeItem(LOGIN_IN_USERNAME);

					ElMessageBox.confirm(res.data.massage, {
						confirmButtonText: "OK",
						showCancelButton: false,
						type: "success",
					})
						.then(() => {
							router.push({
								path: "/Login",
							});
						})
						.catch(() => { });
				} else {
					ElMessageBox.confirm(res.data.massage, {
						confirmButtonText: "OK",
						showCancelButton: false,
						type: "error",
					})
						.then(() => { })
						.catch(() => { });
				}
			});
		}
		function initPage() {
			my_setup.page_data_loading = true;
			getInitPageData()
				.then((res) => {
					let data = res.data;

					my_setup.billing_cids.splice(0);
					if (isNotEmpty(data.billing_cids)) {
						data.billing_cids.forEach((item) => {
							let serial_no = item.serial_no;
							let contact_id = item.contact_id;
							let billto_id = item.billto_id;
							let billto_id_show = item.billto_id_show;

							my_setup.billing_cids.push({
								label: billto_id_show,
								value: serial_no,
							});
						});
					}
				})
				.then(() => {
					defaultOrderdate();
					//初始化map模块
					initSalesMap();
				})
				.then(() => {
					loadPageData();
				})
				.catch((err) => console.log(err))
				.finally(() => { 
					//my_setup.page_data_loading = false
				});
		}
		//表单验证
		function validateForm() {
			let flag = true;

			const order_data_start = my_setup.searchForm.order_data_start;
			const order_data_end = my_setup.searchForm.order_data_end;

			if (
				isEmpty(order_data_start) ||
				isEmpty(order_data_end) ||
				moment(order_data_start).isAfter(order_data_end)
			) {
				ElMessage({
					message: "Please select a correct time period",
					type: "error",
					duration: 4 * 1000,
				});

				flag = false;
			}

			return flag;
		}
		function loadPageData() {
			if (validateForm()) {
				my_setup.page_data_loading = true;
				const serial_no = route.query.id;

				if (isNotEmpty(serial_no)) {
					my_setup.searchForm.billto_cid_ary = [serial_no];
					my_setup.can_change_billing_to_cid = false;
				} else {
					my_setup.can_change_billing_to_cid = true;
				}

				//@test
				/*  my_setup.searchForm.order_data_start = '2023-03-08'
				 my_setup.searchForm.order_data_end = '2023-03-08'
				 my_setup.searchForm.billto_cid_ary = ['LEXHL'] */
				my_setup.searchForm.warehouse_type = "";

				//克隆search form
				let searchForm = cloneDeepObject(my_setup.searchForm);

				searchForm.serial_no = serial_no;
				//searchForm赋值
				const order_date =
					searchForm.order_data_start + ";" + searchForm.order_data_end;
				const billto_cid = searchForm.billto_cid_ary.join(",");
				const order_type_ary = searchForm.order_type_ary;

				searchForm.order_date = order_date;
				searchForm.billto_cid = billto_cid;
				searchForm.order_type = order_type_ary;

				getPageData(searchForm)
					.then((res) => {
						let margin_data = res.data.margin_data;
						if (isNotEmpty(margin_data)) {
							my_setup.dataByModel = margin_data.margin_at_model;
							//console.log(my_setup.dataByModel);
							my_setup.dataByModel_total = margin_data.margin_at_model_total;
							my_setup.dataByOrder = margin_data.margin_at_order;
							my_setup.dataByOrder_total = margin_data.margin_at_order_total;
							my_setup.dataByBillto = margin_data.margin_at_billto;
							my_setup.dataByBillto_total = margin_data.margin_at_billto_total;
							my_setup.main_mark_data = margin_data.margin_at_state;
							my_setup.main_mark_data_storage = margin_data.margin_at_state || [];
						}
					})
					.then(() => {
						backToMainMapData();
						//获取salesmap Register数据
						salesMapRegister();
					})
					.catch((err) => console.log(err))
					.finally(() => {
						my_setup.page_data_loading = false;
					});
			}
		}
		function initSalesMap() {
			//初始化sales map
			//sales report map
			const map_chart_dom = document.getElementById("sales_map_echart");

			sales_map_echart = echarts.init(map_chart_dom);
			sales_map_echart.showLoading();

			//绑定点击事件sales_map_echart
			sales_map_echart.on("click", (params) => {
				changeMapData(params);
			});
		}
		function changeMapData(map_state) {
			const state_name = map_state.name;
			let mapName = my_setup.mapName;

			if (mapName !== state_name) {
				// 重置mapname触发监听器
				my_setup.mapName = state_name;

				//重新绑定main echrt click事件
				sales_map_echart.off("click");
			}
		}
		function backToMainMapData() {
			my_setup.mapName = my_setup.mapName_thumb;
			//重新绑定main echrt click事件
			sales_map_echart.on("click", (params) => {
				changeMapData(params);
			});
		}
		function handleSalesMapChildState() {
			//开启loading界面
			sales_map_echart.showLoading();
			my_setup.searchForm.warehouse_type = "";

			if (my_setup.mapName == my_setup.mapName_thumb) {
				//回退点击，使用原始数据，重新获取salesmap Register数据
				my_setup.main_mark_data = my_setup.main_mark_data_storage.slice();
				salesMapRegister(true);
			} else {
				//不是回退点击，重新获取数据
				reloadSalesMapDatas(true);
			}
		}
		function handleSalesMapWarehouseType() {
			if (
				my_setup.searchForm.warehouse_type == "" &&
				my_setup.mapName == my_setup.mapName_thumb
			) {
				//使用原始数据填充
				my_setup.main_mark_data = my_setup.main_mark_data_storage.slice();
				salesMapRegister();
			} else {
				reloadSalesMapDatas();
			}
		}
		function reloadSalesMapDatas(isClickMapstate) {
			my_setup.page_data_loading = true;

			isClickMapstate = isClickMapstate || false;
			//克隆search form
			let searchForm = cloneDeepObject(my_setup.searchForm);

			//searchForm赋值
			const order_date =
				searchForm.order_data_start + ";" + searchForm.order_data_end;
			const billto_cid = searchForm.billto_cid_ary.join(",");
			const order_type_ary = searchForm.order_type_ary;

			searchForm.order_date = order_date;
			searchForm.billto_cid = billto_cid;
			searchForm.order_type = order_type_ary;
			searchForm.mapmark_statename = my_setup.mapName;
			searchForm.mapmark_warehouse_type = searchForm.warehouse_type;

			getPageData(searchForm)
				.then((res) => {
					let margin_data = res.data.margin_data;
					my_setup.main_mark_data = margin_data.margin_at_state;
				})
				.then(() => {
					//获取salesmap Register数据
					salesMapRegister(isClickMapstate);
				})
				.catch((err) => console.log(err))
				.finally(() => {
					my_setup.page_data_loading = false;
				});
		}
		function salesMapRegister(isHandleState) {
			isHandleState = isHandleState || false;

			//获取地图json结构
			if (echarts.getMap("USA") && !isHandleState) {
				//设置salesmap数据
				getSalesMapDatas();
			} else {
				getMapSourceData({ mapName: my_setup.mapName })
					.then((res) => {
						let mapJson = res.data.mapData;
						//注册map结构
						echarts.registerMap("USA", mapJson, {
							Alaska: {
								left: -131,
								top: 25,
								width: 15,
							},
							Hawaii: {
								left: -110,
								top: 28,
								width: 5,
							},
							"Puerto Rico": {
								left: -76,
								top: 26,
								width: 2,
							},
						});
					})
					.then(() => {
						//设置salesmap数据
						getSalesMapDatas();
					});
			}
		}
		function getSalesMapDatas() {
			//配置map项
			let option = my_setup.main_map_option;
			let mapName = my_setup.mapName;
			let mapName_thumb = my_setup.mapName_thumb;

			my_setup.map_bar = [];
			sales_map_echart.showLoading();

			//获取sales 标记数据   同步

			//地图标记数据
			let main_mark_data = my_setup.main_mark_data;
			if (isNotEmpty(main_mark_data)) {
				let max_mark = 0;
				let min_mark = 0;
				main_mark_data.forEach((item) => {
					max_mark =
						Number(item.value) > Number(max_mark) ? item.value : max_mark;
					min_mark =
						Number(item.value) < Number(min_mark) ? item.value : min_mark;
				});

				option.visualMap.min = min_mark;
				option.visualMap.max = max_mark;

				option.series[0].data = main_mark_data;

				if (typeof sales_map_echart !== "undefined") {
					//装填配置
					sales_map_echart.setOption(option, true);
				}

				//map bar装填
				let map_bar = [];
				main_mark_data.forEach((item, index) => {
					let percent_bar_item_width = parseInt(
						((item.value - min_mark) / (max_mark - min_mark)) * 100
					);
					let item_color = "";

					// '#d31616','#ff2020','#ef4343','#e35555','#ee9595','#00c300','#55e355','#46ed84','#8aec8a','#bff7bf'

					if (percent_bar_item_width >= 90 && percent_bar_item_width <= 100)
						item_color = "#d31616";
					if (percent_bar_item_width >= 80 && percent_bar_item_width < 90)
						item_color = "#ff2020";
					if (percent_bar_item_width >= 70 && percent_bar_item_width < 80)
						item_color = "#ef4343";
					if (percent_bar_item_width >= 60 && percent_bar_item_width < 70)
						item_color = "#e35555";
					if (percent_bar_item_width >= 50 && percent_bar_item_width < 60)
						item_color = "#ee9595";
					if (percent_bar_item_width >= 40 && percent_bar_item_width < 50)
						item_color = "#00c300";
					if (percent_bar_item_width >= 30 && percent_bar_item_width < 40)
						item_color = "#55e355";
					if (percent_bar_item_width >= 20 && percent_bar_item_width < 30)
						item_color = "#46ed84";
					if (percent_bar_item_width >= 10 && percent_bar_item_width < 20)
						item_color = "#8aec8a";
					if (percent_bar_item_width >= 0 && percent_bar_item_width < 10)
						item_color = "#bff7bf";

					/* if (percent_bar_item_width >= 90 && percent_bar_item_width <= 100) item_color = '#ff0000';
					if (percent_bar_item_width >= 80 && percent_bar_item_width < 90) item_color = '#f91515';
					if (percent_bar_item_width >= 70 && percent_bar_item_width < 80) item_color = '#f13535';
					if (percent_bar_item_width >= 60 && percent_bar_item_width < 70) item_color = '#f32D2D';
					if (percent_bar_item_width >= 50 && percent_bar_item_width < 60) item_color = '#ec4544';
					if (percent_bar_item_width >= 40 && percent_bar_item_width < 50) item_color = '#72d645';
					if (percent_bar_item_width >= 30 && percent_bar_item_width < 40) item_color = '#68e245';
					if (percent_bar_item_width >= 20 && percent_bar_item_width < 30) item_color = '#58ed5f';
					if (percent_bar_item_width >= 10 && percent_bar_item_width < 20) item_color = '#46ed84';
					if (percent_bar_item_width >= 0 && percent_bar_item_width < 10) item_color = '#45edb2'; */

					map_bar.push({
						item_amount: "$" + NumericTothreeDigit(item.value, 2),
						item_sold_qty: NumericTothreeDigit(item.total_qty),
						item_order_count: NumericTothreeDigit(item.order_count),
						item_name: item.name,

						item_style: {
							width: percent_bar_item_width + "%",
							backgroundColor: item_color,
						},
					});
				});
				my_setup.map_bar = map_bar;
			} else {
				// sales_map_echart.clear()

				if (typeof sales_map_echart !== "undefined") {
					//装填配置
					option.series[0].data = [];

					sales_map_echart.setOption(option, true);

					my_setup.map_bar = [];
				}
			}

			//数据填充完成，移除echarts loading
			sales_map_echart.hideLoading();
		}
		function salesMapBarHover(index, stateName) {
			/*  let barItem = barItemRefs.value[index]
			 barItem.style = "border:1px solid" */
		}
		function salesMapBarOut(index, stateName) {
			/* barItemRefs.value.forEach((item) => {
				item.style = "border:unset"
			}) */
		}
		function onVisibleChange(visibility) {
			if (visibility && my_setup.initedDatePicker == false) {
				let today = moment().format("MM/DD/YYYY")
				let today_value = moment().format("YYYY-MM-DD")

				let elements = document.getElementsByClassName("order_date_picker_popper")
				if (elements && elements.length > 0) {
					for (let i = 0; i < elements.length; i++) {
						var b_ele = document.createElement("div")
						b_ele.style.color = "red"
						b_ele.style.textAlign = "center"
						b_ele.style.fontSize = "16px"
						b_ele.style.marginLeft = "15px"
						b_ele.style.cursor = "pointer"
						b_ele.style.marginBottom = "5px"
						b_ele.textContent = "Today: " + today

						let item = elements[i]

						b_ele.addEventListener("click", () => {
							if (i == 0) {
								my_setup.searchForm.order_data_start = today_value
							} else if (i == 1) {
								my_setup.searchForm.order_data_end = today_value
							}
						})
						item.appendChild(b_ele)
					}

					my_setup.initedDatePicker = true
				}
			}
		}
		function open_multiline_box(multiline_type) {
			//model multiline click
			let model_names = my_setup.searchForm.model_name
			my_setup.multiline_box = model_names.replaceAll(",", "\n");
			my_setup.dialogMultilineVisible = true;
		}
		function checkMutilineInput() {
			let multiline_box = my_setup.multiline_box;
			my_setup.multiline_box = multiline_box.replaceAll(" ", "");
		}
		function saveMultiline() {
			let multiline_box = my_setup.multiline_box;
			multiline_box = multiline_box.replaceAll(/\n+/g, ",");
			//去重
			let multiline_value = [];
			multiline_box.split(",").forEach((item, index) => {
				if (multiline_value.indexOf(item.trim()) == -1) {
					multiline_value.push(item.trim());
				}
			});
			multiline_box = multiline_value.join(",");

			//model multiline click
			my_setup.searchForm.model_name = multiline_box;
			my_setup.dialogMultilineVisible = false;
		}

		return {
			shortcuts,
			barItemRefs,
			...toRefs(my_setup),
			initPage,
			loadPageData,
			handelLogout,
			username,
			initSalesMap,
			changeMapData,
			salesMapRegister,
			getSalesMapDatas,
			handleSalesMapWarehouseType,
			salesMapBarHover,
			salesMapBarOut,
			onVisibleChange,
			open_multiline_box,
			checkMutilineInput,
			saveMultiline,
		};
	},
};
</script>

<style lang="scss">
:deep(.el-table__footer .el-table__cell .cell) {
	color: #000000;
	font-weight: 600;
}

.el-input-group__append,
.el-input-group__prepend {
	cursor: pointer;
	padding: 0 5px;
}

:deep(.el-main) {
	overflow: hidden;
}

.has-sidebar {
	width: 500px !important;
}

.el-picker-panel [slot=sidebar],
.el-picker-panel__sidebar {
	width: 180px !important;
}

.el-picker-panel [slot=sidebar]+.el-picker-panel__body,
.el-picker-panel__sidebar+.el-picker-panel__body {
	margin-left: 180px !important;
}

.menu {
	border-right: 1px solid #f0f0f0;
	overflow: hidden;
}

.text-center,
[text~="center"] {
	text-align: center;
}

.text-end,
[text~="end"] {
	text-align: end;
}

.box {
	background: #fcf2ed;
	width: 200px;
	height: 150px;
	border-radius: 10px;
	margin: auto;
	position: relative;
}

.homelogo {
	position: absolute;
	top: -25px;
	left: 35px;
}

.emailtext {
	font-size: 12px;
	padding-top: 60px;
	text-align: center;
}

.logout {
	width: 70%;
	background: #e8c2a8;
	border-radius: 8px;
	border-color: #e8c2a8;
	margin-top: 10px;
	font-weight: bold;
	color: #422e21;
}

.header {
	height: 50px;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	box-shadow: 0px 5px 8px 0px #f2f2f2;
}

.name {
	font-size: 18px;
	margin: 0 10px;
}

.Table {
	margin: 2%;
}

.home_head {
	display: flex;
	justify-content: space-between;
}

.datePicker {
	border: 1px solid #a3a3a3;
	border-radius: 5px;
}

.Order {
	color: #4a4a4a;
}

.contentTable {
	margin-top: 20px;
}

.flex2 {
	display: flex;
	justify-content: space-between;
}

.sales-map-box {
	height: 75vh;
	margin-bottom: 150px;
}

.sales_map_bar_box {
	width: 100%;
	height: 100%;
	overflow: auto;
}

.sales_map_bar_box::-webkit-scrollbar {
	/*滚动条整体样式*/
	width: 4px;
	/*高宽分别对应横竖滚动条的尺寸*/
	height: 4px;
	scrollbar-arrow-color: red;
}

.sales_map_bar_box::-webkit-scrollbar-thumb {
	/*滚动条里面小方块*/
	border-radius: 5px;
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: rgba(0, 0, 0, 0.2);
	scrollbar-arrow-color: red;
}

.sales_map_bar_box::-webkit-scrollbar-track {
	/*滚动条里面轨道*/
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	border-radius: 0;
	background: rgba(0, 0, 0, 0.1);
}

.sales_map_bar_box ul {
	height: 75vh;
}

.sales_map_bar_item {
	width: 100%;
	margin-top: 15px;
	color: #000000;
}

.sales_map_bar_item_all {
	width: 100%;
	height: 15px;
	background-color: #f2f2f2;
}

.sales_map_bar_item_tv {
	height: 100%;
	height: 15px;
}
</style>